import axios from 'axios';

const clienteAxios = axios.create({
    baseURL : "http://tcm.mutualargentino.com.ar:8003"
    //baseURL : "http://vimarx.dyndns.biz:33000"
});
/*  clienteAxios.interceptors.response.use((response) => {
    return response
  }, async function (error) {
    if (error.response.status === 401 ) {
        delete clienteAxios.defaults.headers.common['Authorization'];        
    }
    return Promise.reject(error);
  });  */
export default clienteAxios;