import React,{useEffect,useState} from 'react';
import { View, ActivityIndicator,StyleSheet, Alert,Image,Share,Platform } from "react-native";
import { Button, Layout ,TopNav} from 'react-native-rapi-ui';
import { ListItem, Icon } from 'react-native-elements'
import clienteAxios from '../config/axios';
import { Entypo } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";


export default function ({ navigation }) {
	const [autorizaciones,setAutorizaciones]= useState([])
	const [isFetching, setIsFetching] = useState(false);

	const obtenerTransacciones = () => {
		setIsFetching(true);
		clienteAxios.get("/api/commerce/last_trans").then(res => res.data)
			 .then((data) => setAutorizaciones(data.last_tr))
			 .catch(error => {alert("Ocurrió un error obteniendo las autorizaciones.");}).finally( () => setIsFetching(false));
			 
	};
	const anularTransaccion = ( item ) => {
		console.log("ANULANDO")
		setIsFetching(true);  
		let dat = {
		  monto: item.monto *100,
		  nro_autorizacion:item.nro_autorizacion,
		}
		clienteAxios.post("/api/commerce/cancel_card_transaction",dat).then(res => res.data)
			 .then((data) => navigation.navigate("Resultado",{
			  result: data.result,
			  msg: data.nota,
			  nombre: data.nombre,
			  tipo: "ANULACIÓN",
				monto: data.total,
				cuotas:data.cuotas,
				plan:data.plan,
				comercio:data.comercio,
			}))
			 .catch(error => {alert("Ocurrió un error. La anulación no pudo ser procesada.");}).finally(() => setIsFetching(false));

	};
	
	const verCbte = ( item ) => {
		setIsFetching(true);  
		let dat = {
		  monto: item.monto *100,
		  nro_autorizacion:item.nro_autorizacion,
		}
		clienteAxios.post("/api/commerce/get_fact",dat).then(res => res.data)
			 .then((data) => navigation.navigate("Resultado",{
			  result: data.result,
			  msg: data.nota,
			  nombre: data.nombre,
			  tipo: "COMPRA",
				monto: data.total,
				cuotas:data.cuotas,
				plan:data.plan,
				comercio:data.comercio,
				impresion:false,
				nrofa:data.ncom
			}))
			 .catch(error => {alert("Ocurrió un error.");}).finally(() => setIsFetching(false));

	};
	const anularTransaccionAlert = ( item ) => {
		console.log("alerta")
		if(Platform.OS== "web"){
			const res = window.confirm(`Advertencia\nEstas seguro de que quieres anular la transaccion?`)
			if (res) anularTransaccion(item)
			
		} else {Alert.alert(
			"Advertencia",
			"Estas seguro de que quieres anular la transaccion?",
			
			[          
				{
					text: "Cancel",
					
					style: "cancel"
				  },
			  { text: "Anular", onPress: () => anularTransaccion(item) }

			]
		  );}
		
	}
	useEffect(()=>{
        obtenerTransacciones();
    },[]) 
	if (isFetching) {
		return (
			<View style={styles.activityIndicatorContainer}>
				<View
					style={{
					flex: 1,
					justifyContent: "center",
					alignItems: "center",
					}}
				>
					<Image
					resizeMode="contain"
					style={{
						height: 220,
						width: 220,
					}}
					source={require("../../assets/logo.png")}
					/>
						<ActivityIndicator size="large" color="#0000ff" />
				</View>	  
			</View>
		);
	  } else{
	return (
		<Layout>
			<View>
				<TopNav middleContent="Ultimas transacciones"
				rightContent={<Entypo name="ccw" size={24} color="black" />}
				rightAction={() => obtenerTransacciones()}
				/>
			{ autorizaciones ?
				autorizaciones.map((item, i) => (
				<ListItem key={i} bottomDivider>
					<Icon name="money" />
					<ListItem.Content>
					<ListItem.Title>Nro Aut {item.nro_autorizacion}</ListItem.Title>
					<ListItem.Subtitle>${item.monto} - {item.fecha}</ListItem.Subtitle>

					</ListItem.Content>
					<Button  status="danger"
					text="Anular"
					size="md"
					onPress={() => {
						anularTransaccionAlert(item);
					}}
					
					></Button>
					<Button  status="primary"
							size="md"
							color={"#00A0E3"}

                            onPress={() => {
								verCbte(item);
                            }}
							leftContent={
								<Ionicons name="document-text" size={20} color="white" />
							}
                            > </Button>
							
					
				</ListItem>
				))
			: null}
			</View>
		</Layout>
	);
}
}




const styles = StyleSheet.create({

	activityIndicatorContainer:{    
		flex: 1,
		justifyContent: "center",    
		flexDirection: "row",
		justifyContent: "space-around",
		padding: 10    
	},
	image: {
	  flex: 1,
	  justifyContent: "center",
	},
	inputtext:{
	  flex:1,
	  borderTopColor:"#fff",
	  width:50
	},
	textInputStyle:{
	 
	  
	  paddingVertical:5,
	  paddingLeft:15,
	  marginLeft:5,
	  marginRight:0,
	  fontSize:16,
	  flex:1,
	  },
	  inputContainer: {
		borderWidth: 1,
	  borderRadius:8,
	  borderColor:"#d8d8d8",
		marginVertical:7,
		alignContent:"center",
		justifyContent: "center",    
		alignItems: "center",
		  justifyContent: "space-between",
		  flexDirection:"row",
  
	  },
	  container: {
		flex: 1,
		flexDirection: 'column',
		flexWrap: 'wrap',
		marginTop:10,
		marginBottom:20
	  },
  });