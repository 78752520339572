import {
    LOGIN_OK,
    GET_USER,
    LOG_OUT,
    SET_LOADING
} from '../types';
import {
    getItem as getToken,
    removeItem as removeToken,
    setItem as setToken,
    } from "../config/async-storage";

export default (state,action) => {
    switch(action.type){
        case LOGIN_OK:
            setToken(action.payload.token);
            
            return {
                ...state,
                usuario:action.payload.commerce_id,
                token:action.payload.token,
                loading:false,
                nombrecomercio:action.payload.commerce_name,
                cuit:action.payload.commerce_cuit
            }
        case GET_USER:
            return {
                ...state,
                usuario: action.payload.commerce_id, 
                loading:false,
                nombrecomercio:action.payload.commerce_name,
                cuit:action.payload.commerce_cuit
            }
        case SET_LOADING:
            return {
                ...state,                
                loading:true,
            }
        case LOG_OUT:
            removeToken();
            return {
                ...state,
                token:null,
                usuario:null,
                autenticado:null,
                loading:false,
                nombrecomercio:null,
                cuit:null
            }        
        default: return state;
    }
}

    
  