import React, { createContext, useState, useEffect,useReducer } from 'react';
import {
    LOGIN_OK,
    LOG_OUT,
    GET_USER,
    SET_LOADING
} from '../types';
import {
getItem as getToken,
removeItem as removeToken,
setItem as setToken,
} from "../config/async-storage";
import AuthReducer from './AuthReducer';
import clienteAxios from '../config/axios';
import tokenAuth from '../config/token';

const AuthContext = createContext();

const AuthProvider = (props) => {
	// user null = loading
	const initialState = {
        token: null,
        autenticado: null,
        usuario: null,
        loading:false,
        nombrecomercio:null,
        cuit:null
    }
    const [ state, dispatch ] = useReducer(AuthReducer, initialState);
    const userAutenticated = async () => {
        dispatch({
            type:SET_LOADING
        })
        const token = await getToken();
        if(token) {
            tokenAuth(token);            
        }else{

            if(state.usuario == null){
                dispatch({
                    type:LOG_OUT,
                })
            }
        }
        try {
            
            const respuesta = await clienteAxios.post('/api/auth/get_user_commerce');
            dispatch({
                type: GET_USER,
                payload: respuesta.data
            });
        }catch(error){
             if (error.response) {
                if(error.response.status == 401){ 
                    dispatch({
                        type:LOG_OUT,
                    })
                }
            }  
        }

    }
     useEffect(()=>{
        userAutenticated();
    },[]) 
    const logIn = async userData => {
        dispatch({
            type:SET_LOADING
        })
		try{
            const respuesta = await clienteAxios.post('/api/auth/login_commerce', userData)
            .then((respuesta) => {dispatch({
                type:LOGIN_OK,
                payload: respuesta.data
            });
            
        }).catch(error => {alert("Error en el inicio de sesion");dispatch({
            type:LOG_OUT,
        })  });
            const tok = await getToken();
            tokenAuth(await getToken())

        }catch(error){
            if(error.response.status == 401){ 
                dispatch({
                    type:LOG_OUT,
                })
            }      
        }
	}
	const logOut = async () => {
        removeToken();
        dispatch({
            type:LOG_OUT,
        })        
    }


	return (
		<AuthContext.Provider
			value={{
				token:state.token,
				autenticado:state.autenticado,
				usuario:state.usuario,
                loading:state.loading,
                nombrecomercio:state.nombrecomercio,
                cuit:state.cuit,
				logIn,
				logOut,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthProvider };
