import React, { useState ,useContext,useEffect} from "react";
import {
  ScrollView,
  View,
  KeyboardAvoidingView,
  Image,
  ActivityIndicator,
  StyleSheet
} from "react-native";
import {
  Layout,
  Text,
  TextInput,
  Button,
  useTheme,
  themeColor,
} from "react-native-rapi-ui";
import { AuthContext } from "../../provider/AuthProvider";

const Login =  ({ navigation }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const  authContext= useContext(AuthContext);
  const {  logIn,loading} = authContext;
  async function loginUser() {
    var commerce = {
      commerce_cuit:email,
      commerce_password:password
    }
   const result = await logIn(commerce);
  
  }

  if (loading) {
    return (
      <View style={styles.activityIndicatorContainer}>
      <View
        style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        }}
      >
        <Image
        resizeMode="contain"
        style={{
          height: 220,
          width: 220,
        }}
        source={require("../../../assets/logo.png")}
        />
          <ActivityIndicator size="large" color="#0000ff" />
      </View>	  
    </View>
    );
  } 
 
  return (
    <KeyboardAvoidingView behavior="height" enabled style={{ flex: 1 }}>
      <Layout>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
          }}
          keyboardShouldPersistTaps='handled'
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor:  themeColor.white100,
            }}
          >
            <Image
              resizeMode="contain"
              style={{
                height: 180,
                width: 180,
              }}
              source={require("../../../assets/logo.png")}
            />
          </View>
          <View
            style={{
              flex: 3,
              paddingHorizontal: 20,
              paddingBottom: 20,
              backgroundColor: themeColor.white,
            }}
          >
            <Text
              fontWeight="bold"
              style={{
                alignSelf: "center",
                padding: 20,
              }}
              size="h3"
            >
              Iniciar sesión
            </Text>
            <Text>Comercio</Text>
            <TextInput
              containerStyle={{ marginTop: 15 }}
              placeholder="Ingrese su número de comercio"
              value={email}
              autoCapitalize="none"
              autoCompleteType="off"
              autoCorrect={false}
              keyboardType="numeric"
              onChangeText={(text) => setEmail(text)}
              style={{fontFamily: 'system font'
            }}
            />

            <Text style={{ marginTop: 20 }}>Contraseña</Text>
            <TextInput
              containerStyle={{ marginTop: 15 }}
              placeholder="Ingrese su contraseña"
              value={password}
              autoCapitalize="none"
              autoCompleteType="off"
              autoCorrect={false}
              secureTextEntry={true}
              onChangeText={(text) => setPassword(text)}
            />
            <Button
              text={loading ? "Cargando" : "Iniciar Sesión"}
              onPress={() => {
                loginUser();
              }}
              color={"#00A0E3"}
              style={{
                marginTop: 50,
              }}
              disabled={loading}
            />

         
          </View>
        </ScrollView>
      </Layout>
    </KeyboardAvoidingView>
  );
 }

export default Login;
const styles = StyleSheet.create({

  activityIndicatorContainer:{    
      flex: 1,
      justifyContent: "center",    
      flexDirection: "row",
      justifyContent: "space-around",
      padding: 10    
  },
  image: {
    flex: 1,
    justifyContent: "center",
  },
  inputtext:{
    flex:1,
    borderTopColor:"#fff",
    width:50
  },
  textInputStyle:{
   
    
    paddingVertical:5,
    paddingLeft:15,
    marginLeft:5,
    marginRight:0,
    fontSize:16,
    flex:1,
    },
    inputContainer: {
      borderWidth: 1,
      borderRadius:8,
      borderColor:"#d8d8d8",
      marginVertical:7,
      alignContent:"center",
      justifyContent: "center",    
      alignItems: "center",
        justifyContent: "space-between",
        flexDirection:"row",

    },
    container: {
      flex: 1,
      flexDirection: 'column',
      flexWrap: 'wrap',
      marginTop:10,
      marginBottom:20
    },
});
