import React,{useState,useContext} from "react";
import { View ,StyleSheet,Text,KeyboardAvoidingView,ScrollView,Platform} from "react-native";
import {
  Layout,
  TopNav,
  themeColor,
  useTheme,
  Button,
  TextInput
} from "react-native-rapi-ui";
import { Ionicons } from "@expo/vector-icons";
import { Card } from "react-native-elements";
import clienteAxios from "../config/axios";
import { AuthContext } from "../provider/AuthProvider";
import * as Print from 'expo-print';
import * as Sharing from "expo-sharing";
export default function ({ navigation , route}) {
  const { isDarkmode } = useTheme();
  const [nrofact,setFact] = useState(null);
  const {msg,result,nombre, tipo,monto,plan,cuotas,comercio,tarjeta,impresion,nrofa } = route.params;
  const  authContext= useContext(AuthContext);
const {  nombrecomercio,cuit,usuario} = authContext;
  const dat = {
    nro_aut:result,
    nro_fact:nrofact    
  }
  const finalizar = () => {
    if(dat.nro_fact == null || dat.nro_fact == 0){
      navigation.goBack();
    }else{
      clienteAxios.post("/api/commerce/send_nrofact",dat).then(res => res.data)
      .then((data) => navigation.goBack())
      .catch(error => { if(Platform.OS != "web"){ alert("Ocurrió un error durante la carga del Nro Factura");} });
    }
    
    }

    const printCbte = async (  ) => {
      if(msg== "aprobada"){
        let htmlContent = "";
         clienteAxios.post("/api/commerce/print_fact",dat).then(res => res.data)
      .then(async (data) => { await createAndSavePDF(data);
      })
      .catch(error => {alert("Ocurrió un error durante la impresión");});        
      }
    }
    const createAndSavePDF = async (html) => {
      try {
        const { uri } = await Print.printToFileAsync({ html });
        await Sharing.shareAsync(uri);
      } catch (error) {
          alert("Ocurrió un error en la impresión de cbte.");
      }
      };	
  return (
    <KeyboardAvoidingView behavior="height" enabled style={{ flex: 1 }}>

    <Layout>
      <TopNav
        middleContent="Resultado"
        leftContent={
          <Ionicons
            name="chevron-back"
            size={20}
            color={isDarkmode ? themeColor.white100 : themeColor.black}
          />
        }
        leftAction={() => navigation.goBack()}
        rightContent={<Ionicons name="share-social-outline" size={28} color="green" />}
				rightAction={() => printCbte()}
      />
      <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
          }}
          keyboardShouldPersistTaps='handled'
        >
      <View
        style={{
          flex: 1,
          alignItems: "center",
          marginTop:20
        }}
      >
        {msg== "aprobada" ? 
        <Ionicons name="checkmark-circle-outline" size={70} color="green" />
        :
         <Ionicons name="close-circle-outline" size={70} color="red" />
        }
        {
          msg== "aprobada" ?
          <React.Fragment>

          <Card containerStyle={{width:"90%",marginBottom:30,marginHorizontal:"12%"}}>
            <Card.Title style={{marginTop:0,marginBottom:5}}>Tarjeta Mutual</Card.Title>
            <Card.Divider style={{marginTop:5,marginBottom:5}}/>        
            <Card.Title style={{marginTop:0,marginBottom:0}}>{tipo}</Card.Title> 
                  <View style={[styles.marginItems,styles.autoAndMargin,{flexDirection:"row",marginTop:5}]}>
                    <Text >Comercio:</Text>
                    <Text style={styles.textRightMd}>{nombrecomercio}</Text>

                  </View>  
                  <View style={[styles.marginItems,styles.autoAndMargin,{flexDirection:"row"}]}>
                    <Text >{cuit}</Text>
                    <Text style={styles.textRightMd}>{comercio}</Text>

                  </View>                 
                  <Card.Divider style={{marginTop:10,marginBottom:0}}/>        

                  <View style={[styles.marginItems,styles.autoAndMargin,{flexDirection:"row"}]}>
                    <Text >Cliente:</Text>
                    <Text style={styles.textRightMd}>{nombre.trim()}</Text>
                  </View>
                  
                  <View style={[styles.marginItems,styles.autoAndMargin,{flexDirection:"row"}]}>
                    <Text >Nro Aut:</Text>
                    <Text style={styles.textRightMd}>{result}</Text>
                  </View>   
                  
                  <View style={[styles.marginItems,styles.autoAndMargin,{flexDirection:"row",marginBottom:10}]}>
                    <Text >Cuotas: {cuotas}</Text>
                    <Text style={styles.textRightMd}>{ nrofa == null || nrofa == 0 ? null:"Nro Fact: " + nrofa}</Text>
                  </View>  
                  <Card.Divider/>        

                  <View style={[{flexDirection:"row",},styles.autoAndMargin]}>
                    <Text style={{fontWeight:"bold"}}>TOTAL: </Text>
                    <Text style={[{fontWeight:"bold",fontSize:15},styles.textRightMd]}> ${monto}</Text>

                  </View>              
                  <View style={[styles.marginItems]}>
                    <Text style={{ fontWeight:"bold",textAlign:"center"}}>{msg.toUpperCase()}</Text>
                  </View>      
            </Card> 
            {tipo == "ANULACION" || tipo == "ANULACIÓN" || impresion == false ?   null:          
            <React.Fragment>
              <Text style={[styles.marginItems,]}>Nro Factura</Text>
              <TextInput
                containerStyle={{ marginTop: 15,width:"80%" }}
                placeholder="Ingrese el Nro de Factura"
                autoCapitalize="none"
                autoCompleteType="off"
                value={nrofact}
                onChangeText={(val) => setFact(val)}
                autoCorrect={false}
                keyboardType="numeric"
              />
              <Button
                text={"Finalizar"}
                onPress={() => {
                  finalizar();
                }}
                style={{
                  marginTop: 30,
                }}
                color={"#00A0E3"}
              />            
              </React.Fragment>
              }
              
            </React.Fragment>
          :
          <Card containerStyle={{width: "80%"}}>
            <Card.Title >{tipo}</Card.Title>
            <Card.Divider/>        

                  <View >
                    <Text >Mensaje Error: </Text>
                  </View>    
                  <View style={{
                      marginTop:20,
                      marginBottom:20
                    }}>
                    <Text>{msg}</Text>
                  </View>               
          </Card> 
        

        }
          
      </View>
      </ScrollView>

    </Layout>
    </KeyboardAvoidingView>

  );
}
const styles = StyleSheet.create({
 marginItems:{
   marginTop:10
 },
 autoAndMargin:{
  width:"auto",
  justifyContent:"space-between",
 },
 textRightMd:{alignSelf:"flex-end"}
})


