import React, {useState,useContext} from "react";
import { View, ActivityIndicator,StyleSheet ,Alert,Image,ScrollView } from "react-native";
import { Entypo } from "@expo/vector-icons";
import { TextInputMask } from 'react-native-masked-text'
import {
  Layout,
  Text,
  Button,
  TopNav,
  Section,
  SectionContent,
} from "react-native-rapi-ui";
import CardView from "../components/utils/CardView";
import clienteAxios from "../config/axios";
import { AuthContext } from "../provider/AuthProvider";

export default function ({ navigation }) {
  const [monto,setMonto]=useState("");
  const [numero,setNumero]=useState("5047 6364");
  const [pickerValue, setPickerValue] = React.useState(null);
  let moneyField ="";

  const [isFetching, setIsFetching] = useState(false);

const  authContext= useContext(AuthContext);
const {  logOut, usuario,loading} = authContext;
const clearState = () => {
  setMonto("");
  setNumero("5047 6364");
  setPickerValue(null);
}
  const autorizarTarjeta = () => {
    setIsFetching(true);  
//27315368370
    //OPTION 2 - FAKE API
    const numberValue = (moneyField.getRawValue().toFixed(2) * 100);
    const numtar = numero.replace(/\s/g, '');
    if(numtar.length <15 || numberValue == 0 || pickerValue == null || pickerValue == 0 || numberValue == null || monto == ""  ){
      setIsFetching(false)
      Alert.alert(
        "Error",
        "Todos los campos son obligatorios",
        [          
          { text: "OK" }
        ]
      );
    }else if(pickerValue > 24) {
      setIsFetching(false)
      Alert.alert(
        "Error",
        "Solo se permiten hasta 24 cuotas",
        [          
          { text: "OK" }
        ]
      );
    }      
    else {
      let dat = {
        tarjeta_nro: numtar,
        monto: numberValue,
        comercio_nro:usuario,
        cuotas:pickerValue
      }
      clienteAxios.post("/api/commerce/execute_card_transaction",dat).then(res => res.data)
           .then((data) => navigation.navigate("Resultado",{
            result: data.result,
            msg: data.nota,
            nombre: data.nombre,
            tipo: "COMPRA",
            monto: data.total,
            cuotas:data.cuotas,
            plan:data.plan,
            comercio:data.comercio,
            tarjeta:numtar
          }))
           .catch(error => {alert("Ocurrió un error. La transacción no pudo ser procesada.");setIsFetching(false)})
           .finally(() => {setIsFetching(false);clearState();});
    }
    
};
if (isFetching || loading) {
  return (
    <View style={styles.activityIndicatorContainer}>
    <View
      style={{
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      }}
    >
      <Image
      resizeMode="contain"
      style={{
        height: 220,
        width: 220,
      }}
      source={require("../../assets/logo.png")}
      />
        <ActivityIndicator size="large" color="#0000ff" />
    </View>	  
  </View>
  );
} else{
  return (
    <Layout>
      <TopNav
        rightContent={<Entypo name="log-out" size={24} color="black" />}
        rightAction={() => logOut()}
        middleContent="Autorizar Tarjeta"
        />
      <ScrollView
       style={{marginTop:10}}
      >       
      <View
        style={{
          flex: 1,
          flexGrow:1,
          alignItems: "center",
          justifyContent: "center",
          marginHorizontal: 0,
          width: '100%'
        }}
      >      
        <Section style={{          
          width: '95%',
          height:'95%',
          justifyContent:'center',
          paddingTop:50,
          paddingBottom:50
        }}>          
          <SectionContent >                                               
                           <View style={{alignItems:'center'}}>
                           <CardView number={numero} />
                           </View>
                           <View style={{alignItems:'center', width:"90%",marginLeft:"5%" }}>
                           <Text>Numero de tarjeta</Text>

                           <View style={[styles.inputContainer,{width:"90%",maxWidth:300,height:47,marginRight:15,marginLeft:15}]}>

                            <TextInputMask
                              type={'credit-card'}
                              
                              value={numero}
                              onChangeText={text => {
                                setNumero(text)
                              }}
                              style={styles.textInputStyle}
                              keyboardType='numeric'

                            />
                           
                           </View>

                           <View style={[styles.inputContainer,{width:"90%",maxWidth:300,height:47,marginRight:15,marginLeft:15}]}>

                            <TextInputMask
                                type={'money'}
                                options={{
                                  precision: 2,
                                  separator: ',',
                                  delimiter: '.',
                                  unit: '$',
                                  suffixUnit: ''
                                }}
                                placeholder="Monto"

                                value={monto.toString()}
                                onChangeText={text => {
                                  setMonto(text)
                                }}
                                style={[styles.textInputStyle,]}
                                ref={(ref) => moneyField = ref}

                              />
                              </View>
                              <View style={[styles.inputContainer,{width:"90%",maxWidth:300,height:47,marginRight:15,marginLeft:15}]}>
                              <TextInputMask
                                type={'only-numbers'}
                                value={pickerValue}
                                placeholder="Cuotas"
                                style={[styles.textInputStyle,]}

                                onChangeText={(val) => setPickerValue(val)}
                              />
                              </View>
                            </View>
                            
                          <Button
                            text={loading ? "Loading" : "Autorizar"}
                            onPress={() => {
                              autorizarTarjeta();
                            }}
                            style={{
                              marginTop: 50,
                              
                            }}
                            color={"#00A0E3"}
                            disabled={loading}
                          />               
          </SectionContent>
        </Section>
        </View>
      </ScrollView>
    </Layout>
  );
}
}

const styles = StyleSheet.create({

  activityIndicatorContainer:{    
      flex: 1,
      justifyContent: "center",    
      flexDirection: "row",
      justifyContent: "space-around",
      padding: 10    
  },
  image: {
    flex: 1,
    justifyContent: "center",
  },
  inputtext:{
    flex:1,
    borderTopColor:"#fff",
    width:50
  },
  textInputStyle:{
   
    
    paddingVertical:5,
    paddingLeft:15,
    marginLeft:5,
    marginRight:0,
    fontSize:18,
    flex:1,
    },
    inputContainer: {
      borderWidth: 1,
      borderRadius:8,
      borderColor:"#d8d8d8",
      marginVertical:7,
      alignContent:"center",
      justifyContent: "center",    
      alignItems: "center",
        justifyContent: "space-between",
        flexDirection:"row",

    },
    container: {
      flex: 1,
      flexDirection: 'column',
      flexWrap: 'wrap',
      marginTop:10,
      marginBottom:20
    },
});

