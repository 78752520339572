import React, { Component } from 'react'
import { Text, View, StyleSheet,ImageBackground } from 'react-native'
const BASE_SIZE = { width: 300, height: 190 };

const s = StyleSheet.create({
    cardContainer: {
        marginBottom:20
    },
    cardFace: {},
    icon: {
      position: "absolute",
      top: 15,
      right: 15,
      width: 60,
      height: 40,
      resizeMode: "contain",
    },
    baseText: {
      color: "rgba(255, 255, 255, 0.8)",
      backgroundColor: "transparent",
    },
    placeholder: {
      color: "rgba(255, 255, 255, 0.5)",
      fontSize:35,
    },
    focused: {
      fontWeight: "bold",
      color: "rgba(255, 255, 255, 1)",
    },
    number: {
      fontSize: 21,
      position: "absolute",
      top: 103,
      left: 30,
    },
    name: {
      fontSize: 16,
      position: "absolute",
      bottom: 20,
      left: 25,
      right: 100,
    },
    expiryLabel: {
      fontSize: 9,
      position: "absolute",
      bottom: 40,
      left: 218,
    },
    expiry: {
      fontSize: 16,
      position: "absolute",
      bottom: 20,
      left: 220,
    },
    amexCVC: {
      fontSize: 16,
      position: "absolute",
      top: 73,
      right: 30,
    },
    cvc: {
      fontSize: 16,
      position: "absolute",
      top: 80,
      right: 30,
    },
  });

export default class CardView extends Component {
    static defaultProps = {
        placeholder: {
          number: "•••• •••• •••• ••••",
        },    
        scale: 1,
        imageFront: require("../../../assets/card-front.png"),
      };
    render() {
        const { 
             number, 
            placeholder, imageFront } = this.props;
      
         
        return (
            <View style={[s.cardContainer]}>
        
          <ImageBackground style={[BASE_SIZE, s.cardFace]}
            source={imageFront}>
              
              <Text style={[s.baseText, s.number, !number && s.placeholder]}>
                { !number ? placeholder.number : number }
              </Text>
             
             
          </ImageBackground>
          
      </View>
        )
    }
}
