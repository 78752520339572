import React from "react";
import { Platform } from 'react-native';

import { Text, themeColor, useTheme } from "react-native-rapi-ui";
export default (props) => {
  const { isDarkmode } = useTheme();
  return (
    <Text
      fontWeight="bold"
      style={{
        marginBottom: 5,
        marginTop:5,
        color: props.focused
          ? isDarkmode
            ? themeColor.white100
            : "#00A0E3"
          : "rgb(143, 155, 179)",
        fontSize: 11.5,
      }}
    >
      {Platform.OS === "web" ? null:  props.title}
    </Text>
  );
};
