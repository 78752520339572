import React, { useContext } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import { useTheme, themeColor } from "react-native-rapi-ui";
import TabBarIcon from "../components/utils/TabBarIcon";
import TabBarText from "../components/utils/TabBarText";
//Screens
import Home from "../screens/Home";
import Profile from "../screens/Profile";
// Auth screens
import Login from "../screens/auth/Login";
import { AuthContext } from "../provider/AuthProvider";
import Resultado from "../screens/Resultado";

const AuthStack = createStackNavigator();
const Auth = () => {
  return (
    <AuthStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <AuthStack.Screen name="Login" component={Login} />
    </AuthStack.Navigator>
  );
};

const MainStack = createStackNavigator();
const Main = () => {
  return (
    <MainStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <MainStack.Screen name="MainTabs" options={{headerShown: false}} component={MainTabs} />
      <MainStack.Screen name="Resultado" options={{headerShown: false}} component={Resultado} />
    </MainStack.Navigator>
  );
};

const Tabs = createBottomTabNavigator();
const MainTabs = () => {
  const { isDarkmode } = useTheme();
  return (
    <Tabs.Navigator
      screenOptions={{
        style: {
          borderTopWidth: 1,
          borderTopColor: isDarkmode ? themeColor.dark100 : "#ffffff",
          backgroundColor: isDarkmode ? themeColor.dark200 : "#ffffff",
        },
      }}
    >
      {/* these icons using Ionicons */}
      <Tabs.Screen
        name="Home"
        component={Home}
        options={{
          headerShown: false,
          tabBarLabel: ({ focused }) => (
            <TabBarText focused={focused} title="Autorizar" />
          ),
          tabBarIcon: ({ focused }) => (
            <TabBarIcon focused={focused} icon={"md-card"} />
          ),
          tabBarActiveTintColor: "black",

        }}
      />
      <Tabs.Screen
        name="Profile"
        component={Profile}
        options={{
          headerShown: false,
          tabBarLabel: ({ focused }) => (
            <TabBarText focused={focused} title="Historial"/>
          ),
          tabBarActiveTintColor: "#00A0E3",
          tabBarIcon: ({ focused }) => (
            <TabBarIcon focused={focused} icon={"folder-open-outline"} />
          )
          
        }}
      />
      
    </Tabs.Navigator>
  );
};

export default () => {
  const auth = useContext(AuthContext);
  const  authContext= useContext(AuthContext);
  const {  usuario,loading} = authContext;
  return (
    <NavigationContainer>
      {
        usuario == null ? <Auth /> : <Main />
      }

    </NavigationContainer>
  );
};
